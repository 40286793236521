<template>
  <div id="main" />
</template>
<script>
import "echarts/map/js/china.js"; // 引入中国地图数据
import "echarts/map/js/province/guangdong.js";
import echarts from "echarts";
import { reactive, toRefs, onMounted } from "vue";
/*因为echarts 只内置了世界、中国、中国省的地图文件，如果你要使用市级的地图要去找相关的json或者js文件然后在渲染*/
export default {
  props: {
    select: {
      //用来判断是加载哪个地图，China，还是world，还是广东
      type: String,
    },
    datas: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      mapEchartsInit();
    });
    const state = reactive({
      geoCoordMap: {
        黑龙江: [127.9688, 45.368],
        内蒙古: [110.3467, 41.4899],
        吉林: [125.8154, 44.2584],
        辽宁: [123.1238, 42.1216],
        河北: [114.4995, 38.1006],
        天津: [117.4219, 39.4189],
        山西: [112.3352, 37.9413],
        陕西: [109.1162, 34.2004],
        甘肃: [103.5901, 36.3043],
        宁夏: [106.3586, 38.1775],
        青海: [101.4038, 36.8207],
        新疆: [87.9236, 43.5883],
        拉萨: [91.11, 29.97],
        北京: [116.4551, 40.2539],
        四川: [103.9526, 30.7617],
        重庆: [108.384366, 30.439702],
        山东: [117.1582, 36.8701],
        河南: [113.4668, 34.6234],
        江苏: [118.8062, 31.9208],
        安徽: [117.29, 32.0581],
        湖北: [114.3896, 30.6628],
        浙江: [119.5313, 29.8773],
        福建: [119.4543, 25.9222],
        江西: [116.0046, 28.6633],
        湖南: [113.0823, 28.2568],
        贵州: [106.6992, 26.7682],
        云南: [102.9199, 25.4663],
        广东: [113.12244, 23.009505],
        广西: [108.479, 23.1152],
        海南: [110.3893, 19.8516],
        上海: [121.4648, 31.2891],
        北京朝阳店: [116.606526, 39.923464],
        北京大兴店: [116.318949, 39.672324],
        扬州店: [119.421003, 32.393159],
        济南店: [117.120098, 36.6512],
        郑州店: [113.658097, 34.745795],
        南宁店: [108.36637, 22.817746],
        咸阳店: [108.705117, 34.333439],
        潍坊店: [119.107078, 36.70925],
        杭州店: [120.209947, 30.245853],
        哈尔滨店: [126.535319, 45.803131],
        青岛店: [120.374402, 36.168923],
        大同店: [119.099448, 29.306793],
        武汉店: [114.305215, 30.592935],
        苏州店: [120.619585, 31.299379],
        温州店: [120.672111, 28.000575],
        天津店: [117.201538, 39.085294],
        南京店: [118.796623, 32.059352],
        长沙店: [120.501922, 30.192783],
        张家口店: [114.884091, 40.811901],
        张家界店: [110.479921, 29.127401],
        晋中店: [112.736465, 37.696495],
        太原店: [112.549717, 37.87046],
        兰州店: [103.834249, 36.06081],
        合肥店: [117.227308, 31.82057],
      },
    });

    const mapEchartsInit = () => {
      var mapData = [];
      props.datas.forEach((element) => {
        mapData.push({
          name: element[1].name,
          value: element[1],
        });
      });
      // 输出json对象数组,value里包含坐标值和随机值
      function convertData(data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = state.geoCoordMap[data[i].name];
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
          });
        }
        return res;
      }
      //  设置目标点, 配置线条指向
      function convertToLineData(data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          // 起点
          let fromCoord = [116.606526, 39.923464];
          //  终点
          let toCoord = state.geoCoordMap[data[i].name];
          res.push([
            {
              coord: fromCoord,
              value: data[i].value,
            },
            {
              coord: toCoord,
            },
          ]);
        }
        return res;
      }
      const option = {
        backgroundColor: "transparent",
        geo: {
          map: "china",
          aspectScale: 0.75, //长宽比
          zoom: 1.2,
          roam: true,
          itemStyle: {
            normal: {
              borderColor: "#2ab8ff",
              borderWidth: 1,
              // areaColor: "#013C62",
              shadowColor: "#182f68",
              shadowOffsetX: 0,
              shadowOffsetY: 25,
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: "#12235c", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#12235c", // 100% 处的颜色
                  },
                ],
                globalCoord: true, // 缺省为 false
              },
            },
            emphasis: {
              areaColor: "rgba(46,229,206,.6)",
              borderWidth: 0.1,
            },
          },
          // regions: [
          //   {
          //     name: "南海诸岛",
          //     itemStyle: {
          //       areaColor: "rgba(0, 10, 52, 1)",

          //       borderColor: "rgba(0, 10, 52, 1)",
          //       normal: {
          //         opacity: 0,
          //         label: {
          //           show: false,
          //           color: "#009cc9",
          //         },
          //       },
          //     },
          //   },
          // ],
        },
        series: [
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            showEffectOn: "render",
            zlevel: 1,
            rippleEffect: {
              period: 4,
              scale: 0.01,
              brushType: "fill",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "left",
                offset: [1, 0],
                color: "#1DE9B6",
                show: false,
              },
            },
            itemStyle: {
              normal: {
                color: function() {
                  //随机颜色

                  [
                    "#f21347",
                    "#f3243e",
                    "#f33736",
                    "#f34131",
                    "#f34e2b",
                    "#f56321",
                    "#f56f1c",
                    "#f58414",
                    "#f58f0e",
                    "#f5a305",
                    "#e7ab0b",
                    "#dfae10",
                    "#d5b314",
                    "#c1bb1f",
                    "#b9be23",
                    "#a6c62c",
                    "#96cc34",
                    "#89d23b",
                    "#7ed741",
                    "#77d64c",
                    "#71d162",
                    "#6bcc75",
                    "#65c78b",
                    "#5fc2a0",
                    "#5abead",
                    "#52b9c7",
                    "#4fb6d2",
                    "#4ab2e5",
                  ];
                  return (
                    "#" +
                    (
                      "00000" +
                      ((Math.random() * 16777215 + 0.5) >> 0).toString(16)
                    ).slice(-6)
                  );
                },
                shadowBlur: 4,
                shadowColor: "#333",
              },
            },
            symbolSize: 5,
            data: convertData(mapData),
          }, //地图线的动画效果
          {
            type: "lines",
            zlevel: 2,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.4, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: "arrow", //箭头图标
              symbolSize: 5, //图标大小
            },
            lineStyle: {
              normal: {
                color: function() {
                  //随机颜色

                  [
                    "#f21347",
                    "#f3243e",
                    "#f33736",
                    "#f34131",
                    "#f34e2b",
                    "#f56321",
                    "#f56f1c",
                    "#f58414",
                    "#f58f0e",
                    "#f5a305",
                    "#e7ab0b",
                    "#dfae10",
                    "#d5b314",
                    "#c1bb1f",
                    "#b9be23",
                    "#a6c62c",
                    "#96cc34",
                    "#89d23b",
                    "#7ed741",
                    "#77d64c",
                    "#71d162",
                    "#6bcc75",
                    "#65c78b",
                    "#5fc2a0",
                    "#5abead",
                    "#52b9c7",
                    "#4fb6d2",
                    "#4ab2e5",
                  ];
                  return (
                    "#" +
                    (
                      "00000" +
                      ((Math.random() * 16777215 + 0.5) >> 0).toString(16)
                    ).slice(-6)
                  );
                },
                width: 1, //线条宽度
                opacity: 0.1, //尾迹线条透明度
                curveness: 0.3, //尾迹线条曲直度
              },
            },
            data: convertToLineData(mapData),
          },
        ],
      };

      let myChart = echarts.init(document.getElementById("main"));
      myChart.setOption(option, true);
      // console.log(option)
      window.addEventListener("resize", function() {
        myChart.resize();
      });
    };
    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped></style>
