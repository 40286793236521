<template>
  <div class="shop">
    <div class="shop_ban">
      <mapbar
        :datas="provDatas"
        :style="{ width: '100%', height: `${map_canvas}` }"
      />
    </div>
    <div class="shop_map">
      <van-dropdown-menu active-color="#7A4FD6">
        <van-dropdown-item
          v-model="state.currenrtShop"
          :options="shopList"
          @change="changeMenu"
        />
      </van-dropdown-menu>
      <div class="shop_info" v-if="isShow">
        <div class="shop_title">
          <div class="shop_title_left">场馆介绍</div>
          <div class="shop_title_right">VENUE</div>
        </div>
        <div class="shop_subtitle">{{ shopData.introduce }}</div>
        <div class="shop_img">
          <div
            class="item"
            v-for="(item, index) in shopData.shopImg"
            :key="index"
          >
            <img
              v-if="shopData.type === '1'"
              :src="
                require('@/assets/shop/' + currenrtShop + '/' + item + '.png')
              "
            />
            <img
              v-else
              :src="
                require('@/assets/shop/' + currenrtShop + '/' + item + '.jpg')
              "
            />
          </div>
          <div
            class="videoItem"
            v-for="(item, index) in shopData.shopVideo"
            :key="index"
          >
            <video
              webkit-playsinline
              width="1"
              height="1"
              class="vplayinside notaplink"
              x-webkit-airplay
              controls
              loop="loop"
              :src="
                require('@/assets/shop/' + currenrtShop + '/' + item + '.mp4')
              "
            ></video>
          </div>
        </div>
        <div class="shop_title">
          <div class="shop_title_left">场馆位置</div>
          <div class="shop_title_right">LOCATION</div>
        </div>
        <div class="shop_subtitle">{{ shopData.address }}</div>
        <div class="shop_tmap">
          <div id="map"></div>
        </div>
        <div class="shop_btn">
          <van-button type="primary" round block @click="opentxLocation">一键导航</van-button>
        </div>
      </div>
      <div v-else class="shop_info" style="padding:60px 0">
        敬 请 期 待 . . .
      </div>
    </div>
    <contact-info />
  </div>
</template>

<script>
import contactInfo from "../components/ContactInfo.vue";
import { reactive, onMounted, toRefs } from "vue";
import getdata from "../assets/staticData/gameList.json";
import getshopData from "../assets/staticData/shopData.json";
import mapbar from "../components/map1";
import wx from "weixin-js-sdk";
export default {
  name: "Shop",
  components: { contactInfo, mapbar },
  setup() {
    onMounted(() => {
      init();
    });
    const init = async () => {
      state.shopList = getdata.shop_list;
      state.shopData = getshopData.chaoyang;
      console.log("eeeeeeeeeee", state.shopData);
      getMap(state.shopData.lng, state.shopData.lat);
    };
    const getMap = (lng, lat) => {
      var center = new window.qq.maps.LatLng(lng, lat);
      var map = new window.qq.maps.Map(document.getElementById("map"), {
        center: center,
        zoom: 18,
        disableDefaultUI: true,
      });
      var marker = new window.qq.maps.Marker({
        position: center,
        map: map,
      });
      var infoWin = new window.qq.maps.InfoWindow({
        map: map,
      });
      var geocoder = new window.qq.maps.Geocoder({
        complete: function(res) {
          console.log(res);
          // address = res.detail.nearPois[0].name;
        },
      });
      var markerIcon = new window.qq.maps.MarkerImage(
        "https://3gimg.qq.com/lightmap/api_v2/2/4/99/theme/default/imgs/marker.png"
      );
      marker.setIcon(markerIcon);
      window.qq.maps.event.addListener(map, "click", function(event) {
        this.longitude = event.latLng.getLat();
        this.latitude = event.latLng.getLng();
        console.log(event);
        let lat = new window.qq.maps.LatLng(this.longitude, this.latitude);
        geocoder.getAddress(lat);
        setTimeout(() => {
          infoWin.open();
          infoWin.setPosition(event.latLng);
        }, 200);
      });
    };
    const state = reactive({
      currenrtShop: "chaoyang",
      shopList: [],
      shopData: {},
      map_canvas: "100%",
      provDatas: [
        [{ name: "北京朝阳店" }, { name: "北京朝阳店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "北京大兴店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "扬州店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "济南店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "郑州店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "南宁店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "咸阳店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "潍坊店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "杭州店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "哈尔滨店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "青岛店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "大同店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "武汉店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "苏州店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "温州店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "天津店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "南京店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "长沙店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "南京店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "张家口店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "张家界店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "晋中店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "太原店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "兰州店", value: 10 }],
        [{ name: "北京朝阳店" }, { name: "合肥店", value: 10 }],
      ],
      isShow: true,
    });
    const opentxLocation = () => {
      wx.ready(function() {
        wx.openLocation({
          latitude: state.shopData.lat, // 纬度，浮点数，范围为90 ~ -90
          longitude: state.shopData.lng, // 经度，浮点数，范围为180 ~ -180。
          name: "未来战场虚拟现实竞技中心", // 位置名
          address: state.shopData.address, // 地址详情说明
          scale: 18, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
        });
      });
    };
    const changeMenu = (value) => {
      state.shopData = {};
      if (getshopData[value] != undefined) {
        state.shopData = getshopData[value];
        getMap(state.shopData.lng, state.shopData.lat);
        state.isShow = true;
      } else {
        state.isShow = false;
      }
    };
    return {
      ...toRefs(state),
      state,
      changeMenu,
      opentxLocation
    };
  },
};
</script>
<style lang="scss">
.shop {
  width: 100%;
  height: auto;
  overflow: hidden;
  .shop_ban {
    width: 100%;
    height: 300px;
    background: #020933 !important;
  }
  .shop_info {
    width: 100%;
    height: auto;
    background: #0a193a;
    padding: 20px 0;
    .shop_title {
      width: 100%;
      height: auto;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;
      .shop_title_left {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        text-shadow: -1px -1px 0 #182f68, 1px 1px 0 #182f68, 1px 1px 0 #182f68;
        letter-spacing: 1px;
      }
      .shop_title_right {
        font-size: 32px;
        font-weight: bold;
        background-image: -webkit-linear-gradient(left, #2c374b, #0a193a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .shop_subtitle {
      font-size: 14px;
      color: #a7aab9;
      text-align: left;
      padding: 0 10px;
      margin-bottom: 20px;
    }
    .shop_img {
      width: 100%;
      img {
        width: 100%;
      }
      .videoItem{
        width: 100%;
        video {
          width: 100%;
          height: 100%;
          border-radius: 30rpx;
          overflow: hidden;
        }
      }
    }
    .shop_tmap {
      width: 90%;
      height: 200px;
      border-radius: 10px;
      margin: 30px auto;
      overflow: hidden;
      #map {
        width: 100%;
        height: 100%;
      }
    }
    .shop_btn{
      width: 100%;
      height: auto;
      padding: 0 30px;
      box-sizing: border-box;
      opacity: 1;
    }
  }
  .van-dropdown-menu__item {
    justify-content: flex-start;
  }
  .map {
    width: 100%;
    height: 260px;
  }
}
</style>
