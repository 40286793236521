<template>
  <div class="game" v-if="currentType == 'lan'">
    <!-- <nav-box title="游戏专区" /> -->
    <van-tabs
      v-model:active="active"
      sticky
      color="#7A4FD6"
      @change="changeTabs"
    >
      <van-tab
        v-for="(item, index) in gameType"
        :key="index"
        :title="item.name"
        :name="item.id"
      >
      </van-tab>
    </van-tabs>
    <div class="gamelist">
      <div class="game_item" v-for="(item, index) in gameList" :key="index">
        <div class="gameImg">
          <img
            v-if="!item.gameVideo"
            :src="require('@/assets/game/' + item.gameImg + '.jpg')"
            alt=""
          />
          <video
            v-else
            :poster="require('@/assets/game/' + item.gameImg + '.jpg')"
            webkit-playsinline
            width="1"
            height="1"
            class="vplayinside notaplink"
            x-webkit-airplay
            controls
            loop="loop"
            :src="'https://dj-public.nihaofuture.cn/wechart/public/video/' +
              item.gameVideo +
              '.mp4'"
          ></video>
        </div>
        <div class="gameName">{{ item.gameName }}</div>
        <div class="gameInfo">
          <span>{{ item.terrorName }}</span
          ><van-rate
            v-model="value"
            :size="20"
            color="rgb(255, 210, 30)"
            void-icon="star"
            :count="item.terror"
            void-color="rgb(255, 210, 30)"
          />
        </div>
        <div class="gameInfo">
          <span>{{ item.operationName }}</span
          ><van-rate
            v-model="value"
            :size="20"
            color="rgb(255, 210, 30)"
            void-icon="star"
            :count="item.operation"
            void-color="rgb(255, 210, 30)"
          />
        </div>
        <div class="gameInfo">
          <span>{{ item.peopleName }}</span>
          <label>{{ item.people }}</label>
        </div>
        <div class="gameInfo" v-if="item.gameFeatures">
          <span>{{ item.gameFeaturesTitle }}</span>
          <label>{{ item.gameFeatures }}</label>
        </div>
        <div class="gameInfo gameInfo_last">
          <span>{{ item.introduceTitle }}</span>
          <label>{{ item.introduce }}</label>
        </div>
      </div>
    </div>
    <contact-info />
  </div>
  <div class="game" v-else>
    <div class="gamelist gameWan">
      <div class="game_item" v-for="(item, index) in wanGameList" :key="index">
        <div class="gameImg">
          <img
            v-if="!item.gameVideo"
            :src="require('@/assets/game/' + item.gameImg + '.jpg')"
            alt=""
          />
          <video
            v-else
            :poster="require('@/assets/image/' + item.gameImg + '.jpg')"
            webkit-playsinline
            width="1"
            height="1"
            class="vplayinside notaplink"
            x-webkit-airplay
            controls
            loop="loop"
            :src="
              'https://dj-public.nihaofuture.cn/wechart/public/video/' +
              item.gameVideo +
              '.mp4'
            "
          ></video>
        </div>
        <div class="gameName">{{ item.gameName }}</div>
        <div class="gameInfo">
          <span>{{ item.terrorName }}</span
          ><van-rate
            v-model="value"
            :size="20"
            color="rgb(255, 210, 30)"
            void-icon="star"
            :count="item.terror"
            void-color="rgb(255, 210, 30)"
          />
        </div>
        <div class="gameInfo">
          <span>{{ item.operationName }}</span
          ><van-rate
            v-model="value"
            :size="20"
            color="rgb(255, 210, 30)"
            void-icon="star"
            :count="item.operation"
            void-color="rgb(255, 210, 30)"
          />
        </div>
        <div class="gameInfo">
          <span>{{ item.peopleName }}</span>
          <label>{{ item.people }}</label>
        </div>
        <div class="gameInfo" v-if="item.gameFeatures">
          <span>{{ item.gameFeaturesTitle }}</span>
          <label>{{ item.gameFeatures }}</label>
        </div>
        <div class="gameInfo gameInfo_last">
          <span>{{ item.introduceTitle }}</span>
          <label>{{ item.introduce }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";

import getdata from "../assets/staticData/gameList.json";
import contactInfo from "../components/ContactInfo.vue";
export default {
  name: "Game",
  components: { contactInfo },
  setup() {
    const ctx = getCurrentInstance();
    onMounted(() => {
      init();
    });
    const init = async () => {
      state.gameList = getdata.gameList_pvp;

      console.log("ctx...................", ctx);

      state.currentType =
        ctx.appContext.config.globalProperties.$router.currentRoute.value.query.type;
      state.wanGameList = getdata.gameList_wan;
      console.log("33333", state.gameList);
    };
    const state = reactive({
      gameType: [
        { name: "PVP", id: "1" },
        { name: "PVE", id: "2" },
        { name: "Challenge", id: "3" },
      ],
      active: 1,
      gameList: [],
      currentType: "",
      wanGameList: [],
    });
    const changeTabs = (name) => {
      if (name == 1) {
        state.gameList = getdata.gameList_pvp;
      } else if (name == 2) {
        state.gameList = getdata.gameList_pve;
      } else if (name == 3) {
        state.gameList = getdata.gameList_challenge;
      }
    };
    return {
      ...toRefs(state),
      changeTabs,
    };
  },
};
</script>
<style lang="scss" scoped>
.game {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #f4f4f4;
  .gamelist {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 8px;
    .game_item {
      width: 100%;
      height: auto;
      overflow: hidden;
      padding: 10px;
      box-sizing: border-box;
      background: #fff;
      text-align: left;
      margin-bottom: 8px;
      .gameImg {
        width: 100%;
        height: 198px;
        border-radius: 6px 6px 0 0;
        overflow: hidden;
        img {
          width: 100%;
          border-radius: 6px 6px 0 0;
          overflow: hidden;
        }
        video {
          width: 100%;
          height: 100%;
          border-radius: 30rpx;
          overflow: hidden;
        }
      }
      .gameName {
        width: 100%;
        height: 50px;
        background: rgba(99, 49, 240, 0.7);
        color: #fff;
        font-size: 24px;
        font-weight: bolder;
        line-height: 50px;
        text-align: center;
        box-shadow: 5px 5px 10px #ccc;
        margin-bottom: 15px;
        border-radius: 0 0 6px 6px;
      }
      .gameInfo {
        font-size: 14px;
        line-height: 23px;
        display: flex;
        flex-direction: row;
        span {
          width: 20%;
          display: inline-block;
        }
        label {
          width: 80%;
          display: inline-block;
        }
      }
    }
  }
  .gameWan {
    margin-top: 0;
  }
  .gameVideo {
    width: 100%;
    video {
      width: 100%;
      height: 100%;
      border-radius: 30rpx;
      overflow: hidden;
    }
  }
}
</style>
