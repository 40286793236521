<template>
  <div class="about">
    <div class="item">
      <img src="../assets/img/bp_00.png" alt="" />
    </div>
    <div class="item">
      <img src="../assets/img/bp_01.png" alt="" />
    </div>
    <div class="content">
      <div class="about_title">为什么是我们？</div>
      <div class="aboutUs">
        <div class="item" v-for="(item, index) in aboutus_list" :key="index">
          <div class="item_left">
            <img :src="require('@/assets/img/' + item.imgUrl + '.png')" />
          </div>
          <div class="item_right">
            <div class="item_right_up">
              <span>{{ item.title }}</span>
              <label>{{ item.subtitle }}</label>
            </div>
            <div class="item_right_down">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content1">
      <div class="about_title">除了VR+FPS独创了VR+MOBA竞技新模式</div>
      <div class="pattern">
        <div class="pattern_up">
          <div><img src="../assets/img/9.png" alt="" /></div>
          <div><img src="../assets/img/10.png" alt="" /></div>
        </div>
        <div class="pattern_con">
          <div class="item" v-for="(item, index) in patternList" :key="index">
            <div class="item_left">
              <img :src="require('@/assets/img/' + item.imgUrl + '.png')" />
            </div>
            <div class="item_right">
              <span :class="'color' + index">{{ item.title }}</span>
              <label>{{ item.text }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="about_title">完整的VR游戏及竞技产品线满足所有玩家的需求</div>
      <div class="pattern">
        <div class="pattern_game">
          <div><img src="../assets/img/11.png" alt="" /></div>
          <div><img src="../assets/img/12.png" alt="" /></div>
        </div>
        <div class="pattern_game">
          <div><img src="../assets/img/13.png" alt="" /></div>
          <div><img src="../assets/img/14.png" alt="" /></div>
        </div>
        <div class="pattern_game">
          <div><img src="../assets/img/15.png" alt="" /></div>
          <div><img src="../assets/img/16.png" alt="" /></div>
        </div>
        <div class="demand">
          <span> >新手游戏偏向于简洁的操作及快速沉浸式体验 </span>
          <span> >资深玩家偏向于复杂的战术配合及高强度的竞技对抗 </span>
          <span> >层层递进的游戏体验升级也降低玩家的上手门槛同时 </span>
          <span> >快速实现高粘性资深玩家的转化 </span>
        </div>
      </div>
      <div class="about_title">我们的技术超大空间定位精准(行业核心痛点)</div>
      <div class="location">
        <div class="location_img">
          <img src="../assets/img/36.png" alt="" />
        </div>
        <div class="location_text">
          VR竞技需要大空间及精准的定位，市面上在保证定位精
          准的同时只能做到100平米左右，基本无法满足高对抗
          性。而我们2019年率先在北京实现了1000平米的VR竞技空间，10倍于行业平均数值，并且定位精度在1毫米以内。一切源于我们自主研发激光定位系统，每个标识点分布计
          算位置，原理上可以无限扩展定位空间和标识点数量，满足多达百人同场“吃鸡”。
        </div>
      </div>
      <div class="about_title">我们的技术大空间定位数量多(行业核心痛点)</div>
      <div class="location">
        <div class="location_img location_img_one">
          <img src="../assets/img/17.png" alt="" />
        </div>
        <div class="location_text">
          <span
            >1、目前市面大空间游戏只能满足6-8人的同场竞技，我们做到了同场50人，完全覆盖了电竞10人对抗的标准，远高于市场平均数值，还能更多。</span
          >
          <span
            >2、与其他大空间定位设备（动辄百万）的费用相比，我们一套只需要（几十万），稳定可靠，即开即玩，性价比超高。</span
          >
          <span
            >3、我们掌握40项海内外专利，利用激光原理，全球唯一支持超大空间（5000平）VR电竞的公司。</span
          >
        </div>
      </div>
      <div class="about_title">独创的场地复用技术</div>
      <div class="multiplexing">
        <span class="multiplexing_sp1"
          >400平米空间做出2000平米空间效果，完美体验穿 越乐趣</span
        >
        <img src="../assets/img/18.png" alt="" />
        <span class="multiplexing_sp2"
          >同一场地在虚拟世界里分成多层， 每层之间 可以相互穿越
        </span>
        <img src="../assets/img/19.png" alt="" />
        <span class="multiplexing_sp2"
          >不同层的玩家视野里对手均为幽灵状态，所以 不会发生碰撞或重叠</span
        >
      </div>
      <div class="about_title">
        我们的技术还为部队提供高度模拟真实环境的VR 训练系统
      </div>
      <div class="train">
        <div class="train_item">
          <img src="../assets/img/20.png" alt="" />
          <span>精确到每根手指的操作</span>
        </div>
        <div class="train_item">
          <img src="../assets/img/21.png" alt="" />
          <span>精确到每根手指的操作</span>
        </div>
        <div class="train_item">
          <img src="../assets/img/22.png" alt="" />
        </div>
        <div class="train_item">
          <img src="../assets/img/23.png" alt="" />
        </div>
        <p>武警实训场景</p>
      </div>
      <div class="about_title">
        我们做电竞2.0的优势,未来战场通过多项领先于 市场的技术来支持VR电竞2.0落地
      </div>
      <div class="advantage">
        <div class="advantage_item">
          <span style="color:#8E2DF1">超大空间</span>
          <label>超过1000平米的VR定位空间，能兼容更多游戏</label>
        </div>
        <div class="advantage_item">
          <span style="color:#F1A02D">超多人数</span>
          <label>30人以上同场竞技，呈现世界顶尖沉浸式电子竞技体验</label>
        </div>
        <div class="advantage_item">
          <span style="color:#2D57F1">异地联机</span>
          <label>联通任何一个角落的场地</label>
        </div>
        <div class="advantage_item">
          <span style="color:#F12D2D">自由定制</span>
          <label>卡丁车、蹦床、篮球定制内容，更多的可能和想象空间</label>
        </div>
      </div>
      <div class="about_title">
        加盟布局与合作伙伴
      </div>
      <div class="layout">
        <span>八大区域中心店</span>
        <label>
          北京 / 哈尔滨 / 南京宁波 / 合肥 / 厦门泉州 / 佛山 / 成都 / 西安 / 兰州
        </label>
        <div class="layout_partner">
          <div class="layout_partner_out">
            <div class="item">
              <img
                src="../assets/img/25.png"
                style="width: 111px;height: 30px;"
              />
            </div>
            <div class="item">
              <img
                src="../assets/img/26.png"
                style="width: 83px;height: 29px;"
              />
            </div>
          </div>
          <div class="layout_partner_out">
            <div class="item">
              <img
                src="../assets/img/27.png"
                style="width: 95px;height: 21px;"
              />
            </div>
            <div class="item">
              <img
                src="../assets/img/28.png"
                style="width: 64px;height: 30px;"
              />
            </div>
          </div>
          <div class="layout_partner_out">
            <div class="item">
              <img
                src="../assets/img/29.png"
                style="width: 78px;height: 30px;"
              />
            </div>
            <div class="item">
              <img
                src="../assets/img/30.png"
                style="width: 96px;height: 26px;"
              />
            </div>
          </div>
          <div class="layout_partner_out">
            <div class="item">
              <img
                src="../assets/img/31.png"
                style="width: 101px;height: 32px;"
              />
            </div>
            <div class="item">
              <img
                src="../assets/img/32.png"
                style="width: 109px;height:23px;"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="about_title">
        店铺设计装修
      </div>
      <div class="shop">
        <span
          >从200-5000平米，从商场店到街边店
          根据不同场地，设计不同场馆解决方案</span
        >
        <img src="../assets/img/33.png" alt="" />
        <img src="../assets/img/34.png" alt="" />
        <img src="../assets/img/35.png" alt="" />
      </div>
    </div>
    <div class="about_footer">
      <span>“未来”可期，愿与您并肩前行 </span>
      <span>THANK YOU</span>
    </div>
    <contact-info />
  </div>
</template>
<script>
import { reactive, onMounted, toRefs } from "vue";
import contactInfo from "../components/ContactInfo.vue";
import getdata from "../assets/staticData/gameList.json";
export default {
  name: "About",
  components: {
    contactInfo,
  },
  setup() {
    onMounted(() => {
      init();
    });
    const init = async () => {
      state.aboutus_list = getdata.aboutData;
      state.patternList = getdata.patternList;
    };
    const state = reactive({
      aboutus_list: [],
      patternList: [],
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.about {
  width: 100%;
  height: auto;
  overflow: hidden;
  .item {
    width: 100%;
    height: 216px;
    overflow: hidden;
    margin-bottom: 5px;
    img {
      width: 100%;
      height: 100%;
      margin-bottom: -10px;
    }
  }
  .content {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 9px 12px;
    box-sizing: border-box;
    .aboutUs {
      width: 100%;
      height: auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .item {
        width: 100%;
        height: auto;
        overflow: hidden;
        background: #ffffff;
        box-shadow: 0px 5px 5px 0px rgba(88, 88, 88, 0.19);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 15px 15px 21px;
        box-sizing: border-box;
        margin-bottom: 12px;
        .item_left {
          width: 63px;
          height: 63px;
          min-width: 63px;
          max-width: 63px;
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item_right {
          display: flex;
          flex-direction: column;
          text-align: left;
          .item_right_up {
            margin-bottom: 5px;
            span {
              font-size: 15px;
              color: rgba(97, 45, 241, 1);
              font-weight: bold;
              margin-right: 20px;
            }
            label {
              font-size: 12px;
              color: #999999;
            }
          }
          .item_right_down {
            color: #222;
            font-size: 12px;
          }
        }
      }
    }
  }
  .content1 {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 9px 12px;
    box-sizing: border-box;
    background: #fff;
    .pattern {
      width: 100%;
      height: auto;
      overflow: hidden;
      .pattern_up {
        width: 100%;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 9px 0 15px 0;
        div {
          width: 48%;
          margin-right: 4%;
          img {
            width: 100%;
          }
        }
        div:last-child {
          margin-right: 0;
        }
      }
      .pattern_game {
        width: 100%;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 3px 0 0 0;
        div {
          width: 49%;
          margin-right: 2%;
          img {
            width: 100%;
          }
        }
        div:last-child {
          margin-right: 0;
        }
      }
      .pattern_game:first-child {
        margin-top: 10px;
      }
      .demand {
        width: 100%;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 14px 0 23px 0;
        span {
          font-size: 14px;
          color: #222;
          margin-bottom: 5px;
        }
      }
      .item {
        width: 100%;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 18px;
        .item_left {
          width: 33px;
          min-width: 33px;
          max-width: 33px;
          height: 33px;
          margin-right: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item_right {
          display: flex;
          flex-direction: column;
          text-align: left;
          span {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .color0 {
            color: #43aee1;
          }
          .color1 {
            color: #7560bd;
          }
          .color2 {
            color: #ee7cae;
          }
          .color3 {
            color: #eb8255;
          }
          label {
            color: #222;
            font-size: 12px;
          }
        }
      }
    }
    .location {
      width: 100%;
      height: auto;
      overflow: hidden;
      .location_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .location_img_one {
        margin: 6px 0 10px 0;
      }
      .location_text {
        width: 100%;
        color: #222;
        font-size: 14px;
        text-align: left;
        line-height: 24px;
        span {
          display: block;
        }
      }
    }
    .multiplexing {
      width: 100%;
      height: auto;
      overflow: hidden;
      img {
        width: 100%;
      }
      span {
        font-size: 15px;
        color: #222;
      }
      .multiplexing_sp1 {
        text-align: left;
        display: block;
        width: 100%;
        margin: 5px auto;
      }
      .multiplexing_sp2 {
        width: 80%;
        display: block;
        margin: 10px auto;
      }
    }
    .train {
      width: 100%;
      height: auto;
      overflow: hidden;
      margin: 5px auto;
      p {
        text-align: center;
        font-size: 15px;
        color: #222;
      }
      .train_item {
        width: 49%;
        margin-right: 2%;
        float: left;
        margin-bottom: 10px;
        img {
          width: 100%;
        }
        span {
          text-align: center;
          font-size: 15px;
          color: #222;
        }
      }
      .train_item:nth-child(2n) {
        margin-right: 0;
      }
    }
    .advantage {
      width: 100%;
      height: auto;
      overflow: hidden;
      margin: 23px auto;
      .advantage_item {
        width: 98%;
        height: auto;
        overflow: hidden;
        display: flex;
        margin: 0 auto;
        flex-direction: row;
        background: #ffffff;
        box-shadow: 0px 5px 10px 0px rgba(88, 88, 88, 0.19);
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 13px 24px;
        box-sizing: border-box;
        span {
          max-width: 33px;
          min-width: 33px;
          width: 33px;
          font-size: 15px;
          font-weight: bold;
          display: block;
          border-right: 1px solid rgba(88, 88, 88, 0.19);
          padding-right: 24px;
          margin-right: 24px;
        }
        label {
          font-size: 15px;
          color: #222;
          text-align: left;
        }
      }
    }
    .layout {
      width: 100%;
      height: auto;
      overflow: hidden;
      span {
        color: #6332ed;
        font-size: 15px;
        font-weight: bold;
        display: block;
        margin: 10px auto;
      }
      label {
        color: #222;
        font-size: 15px;
        width: 90%;
        margin: 0 auto;
        display: block;
        line-height: 30px;
      }
      .layout_partner {
        width: 100%;
        height: auto;
        overflow: hidden;
        margin: 37px auto;
        .layout_partner_out {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        .item {
          width: 156px;
          height: 69px;
          background: #ffffff;
          box-shadow: 0px 5px 22px 0px rgba(88, 88, 88, 0.19);
          margin: 0 6px 6px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .shop {
      width: 100%;
      height: auto;
      overflow: hidden;
      span {
        font-size: 15px;
        color: #222;
        text-align: left;
        width: 100%;
        display: block;
        margin: 10px auto;
      }
      img {
        width: 100%;
      }
    }
  }
  .about_footer {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 26px 0;
    background: #612DF1;
    span:first-child{
      margin-bottom: 13px;
    }
  }
  .about_title {
    background-image: -webkit-linear-gradient(left, #632ff1, #fff);
    background-image: -o-linear-gradient(left, #632ff1, #fff);
    background-image: -ms-linear-gradient(left, #632ff1, #fff);
    background-image: -moz-linear-gradient(left, #632ff1, #fff);
    background-image: linear-gradient(left, #632ff1, #fff);
    width: 100%;
    height: auto;
    padding: 5px;
    color: rgba(34, 34, 34, 0.7);
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    box-sizing: border-box;
  }
}
</style>
